import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import useApi from "hooks/useApi";
import { CREATE_USER } from "constants/endpoints";
import ErrorMessage from "./ErrorMessage";
import { ALL_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { renameSlugUrl } from "utils/common";
import { API_ENDPOINTS } from "utils/common";
import { SEARCH_FOR } from "constants/defaultState";

const SearchInput = ({ name, label, isRequired, placeholder, onChange, error: formError, propertyType, value="", disabled, setErrors = () => {}, searchFor = SEARCH_FOR.USER }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selected, setSelected] = useState(false);
  const { apiCall } = useApi();
  const dropdownRef = useRef();
  const defaultApiUrl = CREATE_USER;

  const fetchSuggestions = useMemo(
    () =>
      debounce(async (query) => {
        const trimmedQuery = query.trim();
        if (!trimmedQuery) return [];
        setError("");
        try {
          const apiUrl = API_ENDPOINTS[searchFor] || defaultApiUrl;
          const url = `${apiUrl}?search_text=${trimmedQuery}`;
          const response = await apiCall.get(url);
          setResults(response?.data?.users || response?.data?.groups || response?.data?.data || response?.data || []);
          setDropdownVisible(true);
        } catch (err) {
          console.error("Fetch error:", err);
          setError("An error occurred while fetching results.");
        }
      }, 300),
    [apiCall]
  );

  useEffect(() => {
    setQuery(value);
    setSelected(true);
  }, [value]);

  useEffect(() => {
    setQuery("");
    setSelected(false);
    setDropdownVisible(false);
  }, [propertyType]);

  useEffect(() => {
    if (!selected && (query)) {
      setErrors({...formError, owner_id: "Search and select an option from the dropdown." })
    } else {
      setErrors({...formError, owner_id:"" })
    }
  }, [query, value]);

  const handleChange = (event) => {
    setSelected(false);
    setQuery(event.target.value);
    onChange({
      target: {
        name,
        value: ""
      }
    });
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchSuggestions(query);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    try {
    let showName = "";
    if(propertyType === ALL_PROPERTY_TYPE_TAGS.projects){
      showName =  `${suggestion.company.name}  +  ${suggestion._id}`;
    } else if (name === "company_id") {
      showName =  `${suggestion?.name}  +  ${suggestion._id}`;
    } else if(searchFor === SEARCH_FOR.BROKER_GROUP) {
      showName = `${suggestion.name} | ${suggestion.property_type} | ${suggestion.ad_type}`;
    } else {
      showName = `(${suggestion.first_name} ${suggestion.last_name}) + ${suggestion.phone} + ${suggestion.role}`;
    }
    setQuery(showName);
    setSelected(true);
    setDropdownVisible(false);
    onChange({
      target: {
        name,
        value: suggestion._id,
        ...(name === "owner_id" && {
          owner_role: suggestion.role,
          ...(propertyType === ALL_PROPERTY_TYPE_TAGS.projects ? { company_name: suggestion?.company?.name } : {})
        }),
        ...(name === "company_id" && {
          companyName: suggestion.name,
          user_id: suggestion.user_id,
          slug_url: renameSlugUrl(suggestion?.slug_url, suggestion?.name)
        }),
        ...(name === "user_id" && {
          slug_url: suggestion?.slug_url
        })
      }
    });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleFocus = () => {
    if (selected) {
      const selectedText = (propertyType === ALL_PROPERTY_TYPE_TAGS.projects || name === "company_id") ? query?.split("+")[0]?.trim() : query?.split("+")[1]?.trim();
      setQuery(selectedText);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const profileName = (result) => {
    if (propertyType === ALL_PROPERTY_TYPE_TAGS.projects) {
      return `${result?.company?.name}  +  ${result._id}`;
    } else if (name === "company_id") {
      return `${result?.name}  +  ${result._id}`;
    } else if(searchFor === SEARCH_FOR.BROKER_GROUP) {
      return `${result?.name}  |  ${result._id}`;
    } else {
      return `(${result.first_name} ${result.last_name}) + ${result.phone} + ${result.role}`;
    }
  };  

  return (
    <FormControl isInvalid={!!error} mb={4}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Box ref={dropdownRef}>
        <Input
          placeholder={placeholder}
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          label={label}
          disabled={disabled}
        />
        {error && <Box color="red.500">{error}</Box>}
        {dropdownVisible && (
          <Box
            position="absolute"
            top="100%"
            left="0"
            right="0"
            mt={2}
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
            maxH="200px"
            overflowY="auto"
            bg="white"
            zIndex="10"
          >
            {results?.length > 0 ? (
              <List spacing={2}>
                {results.map((result, index) => (
                  <ListItem
                    key={index}
                    padding="8px"
                    borderBottom="1px solid #eaeaea"
                    cursor="pointer"
                    onClick={() => handleSuggestionClick(result)}
                  >
                    {profileName(result)}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box color="red.500">Result not found</Box>
            )}
          </Box>
        )}
      </Box>
      <ErrorMessage error={formError}/>
    </FormControl>
  );
};

export default SearchInput;
