import { Box, Flex, useColorModeValue, GridItem, Grid, Button, useDisclosure } from '@chakra-ui/react';

import MiniStatistics from 'components/card/MiniStatistics';
import { DASHBOARD_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import React, { useCallback, useEffect, useState } from 'react';
import CheckTable from 'views/admin/default/components/CheckTable';

import {
  columnDataTotalAds,
  columnsDataActiveAds,
} from 'views/admin/default/variables/columnsData';
import { OverlayLoaderComponent } from 'components/loader/Loader';
import { transformedTotalAdsDataFn } from 'utils/common';
import { transformedActiveAdsDataFn } from 'utils/common';
import FilterCalendar from 'components/calendar/FilterCalendar';
import { formatDateToDDMMYYYY } from 'utils/common';
import { DASHBOARD_USER } from 'constants/endpoints';
import { transformUserData } from 'utils/common';
import { columnsDataUserStatus } from './variables/columnsData';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { DOWNLOAD_SITEMAP_API } from 'constants/endpoints';
import ToggleSwitch from 'components/toggleSwitch';
import { APP_SETTINGS_API } from 'constants/endpoints';
import { submitResponse } from './services/DashBoardServices';
import { useModal } from 'contexts/ModalContext';

const today = new Date();
const tenDaysAgo = new Date();
tenDaysAgo.setDate(today.getDate() - 10);
const initialAppSettings = {
  user_account_delete: false
};
export default function UserReports() {
  const { apiCall, error, loading } = useApi();
  const [dashboardData, setDashboardData] = React.useState({});
  const [userResponseData, setUserResponseData] = React.useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [appSettings, setAppSettings] = useState(initialAppSettings);
  const { openModal } = useModal();

  const fetchData = useCallback(async () => {
    try {
      const [dashboardData, userResponse] = await Promise.all([
        apiCall.get(DASHBOARD_API, {
          start_date: formatDateToDDMMYYYY(startDate),
          end_date: formatDateToDDMMYYYY(endDate),
        }),
        apiCall.get(DASHBOARD_USER),
      ]);
      setDashboardData(dashboardData?.data);
      setUserResponseData(userResponse?.data);
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchData();
    fetchAppSettings();
  }, [fetchData]);

  const handleDownload = async () => {
    try {
      const data = await apiCall.get(DOWNLOAD_SITEMAP_API, null, 'blob');
      console.log("download sitemap function calling---------------")
      const blob = new Blob([data], { type: "application/zip" });
      const urlObject = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlObject;
      link.download = "sitemap.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    total_active_ads = 0,
    total_ads = 0,
    total_pending_verification_ads = 0,
  } = dashboardData;

  const activeAgents = userResponseData?.agents?.active;
  const pendingVerificationAgents =
    userResponseData?.agents?.pending_verification;

  const activeBuilder = userResponseData?.builders?.active;
  const pendingVerificationbuilders =
    userResponseData?.builders?.pending_verification;

  const activeProject = userResponseData?.projects?.active;
  const pendingVerificationProject =
    userResponseData?.projects?.pending_verification;

  const statistics = {
    'Total Ads': [
      {
        name: 'Active',
        value: total_active_ads,
        link: '/admin/ads?property_status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending',
        value: total_pending_verification_ads,
        link: '/admin/ads?property_status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Agent: [
      {
        name: 'Active Agent',
        value: activeAgents,
        link: '/admin/agents?status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Agent',
        value: pendingVerificationAgents,
        link: '/admin/agents?status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Builder: [
      {
        name: 'Active Builder',
        value: activeBuilder,
        link: '/admin/builders?status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Builder',
        value: pendingVerificationbuilders,
        link: '/admin/builders?status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Projects: [
      {
        name: 'Active Project',
        value: activeProject,
        link: '/admin/ads?property_status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Project',
        value: pendingVerificationProject,
        link: '/admin/ads?property_status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
  };

  const transformedTotalAdsData = transformedTotalAdsDataFn(dashboardData);
  const transformedActiveAdsData = transformedActiveAdsDataFn(dashboardData);
  const transformUserDatas = transformUserData(userResponseData);

  const categories = Object.entries(statistics);

  const updatedTransformedActiveAdsData = [
    ...transformedActiveAdsData,
    {
      type: 'PG',
      rent: dashboardData?.pg?.active || 0,
      sell: 0,
      total_ads: dashboardData?.pg?.active || 0,
    },
    {
      type: 'Plot',
      rent: 0,
      sell: dashboardData?.plot?.active || 0,
      total_ads: dashboardData?.plot?.active || 0,
    },
    {
      type: 'Share Flat',
      rent: dashboardData?.share_flat?.active || 0,
      sell: 0,
      total_ads: dashboardData?.share_flat?.active || 0,
    },
  ];

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  const updateAppSettings = async (name, checked) => {
    try {
      const payload = {
        key: name,
        value: checked,
      };
      const url = APP_SETTINGS_API;
      const response = await submitResponse({ apiCall, payload, url });
      if (response.data) {
        setAppSettings((prev) => ({
          ...prev,
          [name]: checked,
        }));
      };
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleToggleChange = async (e) => {
    const { name, checked } = e.target;
    openModal({
      title: 'Update Status',
      bodyText: `Are you sure you want to <strong>${checked ? "Allow" : "Prevent"}</strong> users from deleting their accounts?`,
      actions: [
        {
          label: `Yes, ${checked ? "Allow" : "Prevent"}`,
          colorScheme: 'blue',
          onClick: () => {updateAppSettings(name, checked)},
        },
      ],
    });
  };

  const fetchAppSettings = async () => {
    try {
      const url = `${APP_SETTINGS_API}`;
      const response = await apiCall.get(url);
      if(response.data){
        const {key, value} = response.data[0];
        setAppSettings((prev) => ({
          ...prev,
          [key]: value,
        }));
      }
    } catch (error) {
      console.error("Error fetching app settings:", error);
    }
  };

  return (
    <Box pt="20px" overflowX={"hidden"} w="93%" minH="100vh">
      <Flex gap="15px" mb="20px" justifyContent="space-between" alignItems="center">
        {categories.map(([categoryName, stats]) => (
          <MiniStatistics
            key={categoryName}
            categoryName={categoryName}
            stats={stats}
          />
        ))}
        <FilterCalendar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Button colorScheme="pink" onClick={handleDownload} w="600px">
          Download XML
        </Button>
        <ToggleSwitch
          onChange={handleToggleChange}
          name="user_account_delete"
          isChecked={appSettings.user_account_delete}
          label="Allow Users to Delete Account"
        />
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)" gap={1}>
        <Grid templateRows="repeat(2, 1fr)" rowGap={0} width={"70%"}>
          <GridItem mb={0}>
            <CheckTable
              columnsData={columnDataTotalAds}
              tableData={transformedTotalAdsData}
              title="Total Ads"
            />
          </GridItem>

          <GridItem mb={0}>
            <CheckTable
              columnsData={columnsDataUserStatus}
              tableData={transformUserDatas}
              title="Total Users"
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem>
            <CheckTable
              columnsData={columnsDataActiveAds}
              tableData={updatedTransformedActiveAdsData}
              title="Total Active Ads"
            />
          </GridItem>
        </Grid>
      </Grid>

      <OverlayLoaderComponent isOpen={loading} />
    </Box>
  );
}
