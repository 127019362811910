import React from 'react';
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdOutlineMoreHoriz,
  MdOutlinePerson,
  MdOutlineCardTravel,
  MdOutlineLightbulb,
  MdDelete,
  MdBlock,
} from 'react-icons/md';
import { PROPERTY_STATUS } from 'constants/filterDropdownData';
import { useModal } from 'contexts/ModalContext';
import { getStatusColor } from 'utils/common';
import { USER_REQUIREMENT_ACTIONS } from 'constants/defaultState';
import { LEAD_TYPES_TAGS } from 'constants/defaultState';

export default function Banner(props) {
  const {
    data,
    onUpdate = () => {},
    currentValue,
    onSelectedAction = () => {},
    onUpdatePropertyStatus = () => {},
    selectedAction,
    ...rest
  } = props;

  const { openModal } = useModal();

  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.15)',
    'unset'
  );
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );
  const borderColorOpen = useColorModeValue('blue.500', 'blue.300');

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const getAvailableStatuses = (currentStatus) => {
    switch (currentStatus) {
      case 'Pending':
        return ['Rejected', 'Active', 'Edit'];
      case 'Inactive':
        return ['Active', 'Edit'];
      case 'Active':
        return ['Inactive', 'Edit'];
      case 'Expired':
        return ['Active', 'Edit'];
      case 'Rejected':
        return ['Pending', 'Edit'];
      case 'Deleted':
        return ['Active', 'Edit'];
      case 'Verified':
        return ['Edit', USER_REQUIREMENT_ACTIONS.ADD_REQUIREMENT];  
      case 'Unverified':
        return ['Edit', USER_REQUIREMENT_ACTIONS.ADD_REQUIREMENT];
      case 'Add':
          return [USER_REQUIREMENT_ACTIONS.EDIT_REQUIREMENT, USER_REQUIREMENT_ACTIONS.SEND_LEAD, USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT, USER_REQUIREMENT_ACTIONS.DELETE_REQUIREMENT];  
      case LEAD_TYPES_TAGS.shared:
          return [USER_REQUIREMENT_ACTIONS.EDIT_REQUIREMENT, USER_REQUIREMENT_ACTIONS.SEND_LEAD, USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT];  
      case 'Update':
        return ['Edit'];  
      default:
        return [];
    }
  };

  const handleMenuClick = () => {
    onOpen1();
    onSelectedAction();
  };

  const handleMenuItemClick = (selectedStatus) => {
    const updatedPropertyType = PROPERTY_STATUS.find(
      (item) => item.label === selectedStatus
    )?.value ?? null;

    onUpdatePropertyStatus({
      updatedPropertyType,
      type: selectedAction.collection_type,
      slug: selectedAction.slug_url,
      id: selectedAction._id,
      status: selectedStatus
    });
  };

  const statusIcons = {
    Rejected: MdBlock,
    Active: MdOutlineCardTravel,
    Inactive: MdOutlinePerson,
    Pending: MdOutlineLightbulb,
    Delete: MdDelete,
  };

  const handleMenuItemClickModalOpen = (selectedStatus) => {
    if(selectedStatus === "Edit"){
      const updatedPropertyType = PROPERTY_STATUS.find(
        (item) => item.label === selectedStatus
      ).value;
      onUpdatePropertyStatus({
        updatedPropertyType,
        type: selectedAction.collection_type,
        slug: selectedAction.slug_url,
        id: selectedAction._id
      });
      return;
    }
    if(selectedStatus === "Add" || selectedStatus === USER_REQUIREMENT_ACTIONS.SEND_LEAD){
      onUpdatePropertyStatus({
        id: selectedAction._id,
        status: selectedStatus
      });
      return;
    }
    if([USER_REQUIREMENT_ACTIONS.ADD_REQUIREMENT, USER_REQUIREMENT_ACTIONS.EDIT_REQUIREMENT, USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT].includes(selectedStatus)){
      onUpdatePropertyStatus({
        updatedPropertyType: selectedStatus === USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT ? USER_REQUIREMENT_ACTIONS.VIEW_REQUIREMENT : USER_REQUIREMENT_ACTIONS.ADD_REQUIREMENT,
        id: selectedAction._id,
        status: selectedStatus
      });
      return;
    }
    const isDeleteRequirement = selectedStatus === USER_REQUIREMENT_ACTIONS.DELETE_REQUIREMENT;
    openModal({
      title: `${isDeleteRequirement ? 'Delete Requirement' : 'Update Status'}`,
      bodyText: `Are you sure you want ${ isDeleteRequirement ? '' : 'to update status'} to
        <span style="font-weight: bold;">${selectedStatus}</span>
      ? `,
      actions: [
        {
          label: isDeleteRequirement ? 'Yes, Delete' : `Yes, Update`,
          colorScheme: getStatusColor(selectedStatus).colorScheme || 'blue',
          onClick: () => {
            handleMenuItemClick(selectedStatus);
          },
        },
      ],
    });
  };

  return (
    <Menu isOpen={isOpen1} onClose={onClose1} placement="right-start">
      <MenuButton
        align="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="37px"
        h="37px"
        lineHeight="100%"
        onClick={handleMenuClick}
        borderRadius="10px"
        border={isOpen1 ? `2px solid ${borderColorOpen}` : 'none'}
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        w="170px"
        minW="unset"
        maxW="170px !important"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="10px"
        p="10px"
        mr="10px"
        style={{ zIndex: 5 }}
      >
        {getAvailableStatuses(currentValue)
          ?.filter((item) => item !== currentValue)
          ?.map((item, index) => (
            <MenuItem
              key={item}
              fontSize={'md'}
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              mt={index !== 0 ? '8px' : '0px'}
              onClick={() => handleMenuItemClickModalOpen(item)}
            >
              <Flex align="center">
                <Icon as={statusIcons[item]} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  {item}
                </Text>
              </Flex>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}
