import { useState } from 'react';
import axios from 'axios';

// Utility function to get the token
const getToken = () => {
  return localStorage.getItem('token');
};

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiRequest = async (method, endpoint, data = null, params, responseType = 'json') => {
    setLoading(true);
    setError(null);

    const config = {
      method: method,
      url: process.env.REACT_APP_API_BASE_URL + endpoint,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_XAPIKEY,
      },
      data: data,
      params: params,
      responseType
    };

    try {
      const response = await axios(config);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError(err);

      throw err;
    }
  };

  const apiCall = {
    get: (endpoint, params, responseType = 'json') => apiRequest('GET', endpoint, null, params, responseType),
    post: (endpoint, data, params) =>
      apiRequest('POST', endpoint, data, params),
    put: (endpoint, data, params) => apiRequest('PUT', endpoint, data, params),
    patch: (endpoint, data, params) =>
      apiRequest('PATCH', endpoint, data, params),
    delete: (endpoint, data, params) => apiRequest('DELETE', endpoint, data, params),
  };

  return { apiCall, loading, error };
};

export default useApi;
