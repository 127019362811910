import { Box, Text, Tooltip } from "@chakra-ui/react";

export const requirementsColumn = [
  {
    Header: "CREATED AT",
    accessor: "created_at",
  },
  {
    Header: "UPDATED AT",
    accessor: "updated_at",
  },
  {
    Header: "NAME",
    accessor: "full_name",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "PROPERTY TYPE",
    accessor: "property_type",
  },
  {
    Header: "AD TYPE",
    accessor: "ad_type",
  },
  {
    Header: "GROUP NAME",
    accessor: "leads",
    Cell: ({ value }) => {
      return (
        <Box>
          {value.map((group, index) => (
            <Tooltip
              key={index}
              label={
                group.users.map(
                  (user) => `${user.first_name} ${user.last_name || ""}`
                ).join(", ")
              }
              placement="top"
              hasArrow
            >
              <Text
                cursor="pointer"
                whiteSpace="nowrap"
                style={{ fontWeight: 600 }}
                mb={index < value.length - 1 ? 2 : 0}
              >
                {group.group_name}
              </Text>
            </Tooltip>
          ))}
        </Box>
      );
    },
  },
  {
    Header: "SEEN BY",
    accessor: "seen_by",
    Cell: ({ value }) => {
      const names = value ? value.split(",") : [];
      const showTooltip = names.length > 3;

      return (
        <Tooltip label={names.join(", ")} placement="top" isDisabled={!showTooltip}>
          <Text
            cursor={showTooltip ? "pointer" : "default"}
            whiteSpace="nowrap"
          >
            {showTooltip
              ? `${names.slice(0, 3).join(", ")}...`
              : names.join(", ")}
          </Text>
        </Tooltip>
      );
    },
  },
  {
    Header: "COMMENT",
    accessor: "comment",
    Cell: ({ value }) => {
      const showTooltip = value.length > 40;
      return (
        <Tooltip label={value} placement="top" isDisabled={!showTooltip}>
          <Text
            cursor={showTooltip ? "pointer" : "default"}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxWidth="40ch"
          >
            {value}
          </Text>
        </Tooltip>
      );
    },
  },
  {
    Header: "REQUIREMENT ADDRESS",
    accessor: "address"
  },
  {
    Header: "CREATED BY",
    accessor: "created_by",
  },
];
