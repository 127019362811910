import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { USERS_LISTING_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { userColumns } from 'views/admin/users/variables';
import {
  ROLE_DROPDOWN_DATA,
  PLAN_DROPDOWN_DATA,
  PLATFORM_DROPDOWN_DATA,
  ONBOARDING_DROPDOWN_DATA,
  EMAIL_TYPE_FILTER_OPTIONS
} from 'constants/filterDropdownData';
import Pagination from 'components/pagination/Pagination';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { formatUsersTableData } from 'utils/common';
import {
  UPDATE_USER_AND_PROPERTY_API,
  GET_USER_API_FN,
} from 'constants/endpoints';
import { showToast } from 'components/toast/Toast';
import { OverlayLoaderComponent } from 'components/loader/Loader';
import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { ENTITY_FILTER_TYPES } from 'utils/common';
import { getFilterFromLocalStorage } from 'utils/common';
import { setFilterToLocalStorage } from 'utils/common';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import ManageColumns from 'components/manageColumns/ManageColumns';
import NoDataFound from 'components/noDataFound/NoDataFound';
import { SORT_DIRECTIONS } from 'constants/filterDropdownData';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';
import { FiChevronsDown, FiFilter } from 'react-icons/fi';
import CustomTable from 'components/table/CustomTable';
import { formatDateToDDMMYYYY } from 'utils/common';
import SignupFormModal from 'components/form/Signup';
import { PHONE_VERIFIED_DROPDOWN_DATA } from 'constants/filterDropdownData';
import { USER_STATUS } from 'constants/filterDropdownData';
import Menu from 'components/menu/MainMenu';
import UserRequirementModal from './components/modal/UserRequirementModal';
import { USER_REQUIREMENT_ACTIONS } from 'constants/defaultState';

const PAGE_SIZE = 50;
const today = new Date();
const tenDaysAgo = new Date();
tenDaysAgo.setDate(today.getDate() - 10);

export default function Users() {
  const { apiCall, error, loading } = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openRequirementForm, setOpenRequirementForm] = useState(false);
  const lsUsersDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USERS_DATE_RANGE
  );
  const [startDate, setStartDate] = useState(
    lsUsersDateRange ? lsUsersDateRange[0] : ''
  );
  const [endDate, setEndDate] = useState(
    lsUsersDateRange ? lsUsersDateRange[1] : ''
  );
  const lsFiltersUsersPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.USERS_PAGE_SIZE
  );
  const [pageSize, setPageSize] = useState(
    lsFiltersUsersPageSize ? lsFiltersUsersPageSize : PAGE_SIZE
  );
  const lsFilters = getFilterFromLocalStorage(ENTITY_FILTER_TYPES.USERS_FILTER);
  const initialFIlterData = {
    role: '',
    plan: '',
    onboardingCompleted: '',
    emailType: '',
    phoneVerified: '',
    platform: '',
    sortType: 'created_at',
    sortDirection: 'descending',
  };
  const [filters, setFilters] = useState(
    lsFilters ? lsFilters : { ...initialFIlterData }
  );
  const {
    role = "",
    plan = "",
    onboardingCompleted = "",
    emailType = "",
    phoneVerified = "",
    platform = "",
    sortType = "",
    sortDirection = "",
  } = filters || {};
  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [filterRemoveFlagCount, setFilterRemoveFlagCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const { search } = useLocation();
  const queryObject = Object.fromEntries(new URLSearchParams(search));
  const [manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox, setManageColumnSelectAllCheckbox] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedUser, setSelectedUser] = useState({});

  const fetchData = useCallback(
    async (text) => {
      try {
        const data = await apiCall.get(`${USERS_LISTING_API}`, {
          role,
          onboarding_completed: onboardingCompleted,
          email_type: filters?.emailType,
          phone_verified: filters?.phoneVerified,
          plan,
          platform,
          search_text: text,
          page: currentPage,
          limit: pageSize,
          sort_by: sortType,
          sort_order: sortDirection,
          start_date: formatDateToDDMMYYYY(startDate),
          end_date: formatDateToDDMMYYYY(endDate),
        });
        const formatData = data?.data?.users;
        setTotalPages(Math.ceil(data?.data?.count / pageSize));
        setTotalCount(data?.data?.count);
        setUserData(formatData);
      } catch (error) {
        console.log(error);
      }
    },
    [
      role,
      onboardingCompleted,
      emailType,
      phoneVerified,
      plan,
      platform,
      searchText,
      currentPage,
      pageSize,
      sortType,
      sortDirection,
      startDate,
      endDate,
    ]
  );

  useEffect(() => {
    if (queryObject?.search_text) {
      setSearchText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      setSearchText('')
      fetchData();
      setFilterToLocalStorage(ENTITY_FILTER_TYPES.USERS_FILTER, filters);
    }
  }, [filterRemoveFlagCount, currentPage, queryObject?.search_text, pageSize]);

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setCurrentPage(1);
  }, []);

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];
    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.USERS_FILTER, filters);
    setCurrentPage(1);
    fetchData();
  };

  const handleRemoveAllFilter = () => {
    setFilteredData([]);
    setFilters({ ...initialFIlterData, emailType: '', phoneVerified: '' });
    setEndDate('');
    setStartDate('');
  };

  const onPhoneNumberClick = async (phone) => {
    try {
      const user = userData.find((user) => user.phone === phone);
      const data = await apiCall.get(GET_USER_API_FN(user._id));
      setJsonData(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateUser = async ({ id, data }) => {
    try {
      await apiCall.patch(UPDATE_USER_AND_PROPERTY_API, {
        updateData: [
          {
            type: 'users',
            _id: id,
            data,
          },
        ],
      });
      showToast({
        message: 'User updated successfully',
        customStyle: {
          background: 'green',
          color: '#fff',
          padding: '10px',
        },
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const dropdownFilters = [
    {
      name: 'role',
      data: ROLE_DROPDOWN_DATA,
      placeholder: 'Select Role',
      value: role,
    },
    {
      name: 'plan',
      data: PLAN_DROPDOWN_DATA,
      placeholder: 'Select Plan',
      value: plan,
    },
    {
      name: 'onboardingCompleted',
      data: ONBOARDING_DROPDOWN_DATA,
      placeholder: 'Select Onboarding Status',
      value: onboardingCompleted,
    },
    {
      name: 'emailType',
      data: EMAIL_TYPE_FILTER_OPTIONS,
      placeholder: 'Select Email Type Status',
      value: emailType,
    },
    {
      name: 'phoneVerified',
      data: PHONE_VERIFIED_DROPDOWN_DATA,
      placeholder: 'Select phone Verified Status',
      value: phoneVerified,
    },
    {
      name: 'platform',
      data: PLATFORM_DROPDOWN_DATA,
      placeholder: 'Select Platform',
      value: platform,
    },
  ];

  const SORT_TYPES = [
    {
      label: 'Created At',
      value: 'created_at',
    },
    {
      label: 'Total Properties',
      value: 'total_properties',
    },
  ];

  const dropdownSorts = [
    {
      name: 'sortType',
      placeholder: '',
      value: sortType,
      data: SORT_TYPES,
    },
    {
      name: 'sortDirection',
      placeholder: '',
      value: sortDirection,
      data: SORT_DIRECTIONS,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    sorts: dropdownSorts,
  };

  const columns = useMemo(() => userColumns, [userColumns]);
  const data = useMemo(() => formatUsersTableData(userData), [userData]);

  const lsLocalStorageManageColumns = getFilterFromLocalStorage(
    ENTITY_COLUMNS_TYPES.USERS_COLUMNS
  );

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = true;
          return acc;
        }, {})
  );

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.USERS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );

  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);
      fetchData(text);
    },
    [fetchData]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();
    handleFetchSearch(searchText);
  };

  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find(
        (item) => item.phone === row.phone
      );
      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item.phone !== findData.phone);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  const handleUserAction = async ({
    updatedPropertyType,
    id: selectedId
  }) => {
    try {
      const data = userData.find((item) => item?._id === selectedId);
      const { first_name, last_name, role, _id, phone, email } = data;
      if(updatedPropertyType === '/admin/form'){
        setOpenLoginForm(true);
        setSelectedUser({ first_name, last_name, role, _id, phone, email })
      }
      if(updatedPropertyType === USER_REQUIREMENT_ACTIONS.ADD_REQUIREMENT){
        setOpenRequirementForm(true);
        setSelectedUser({ first_name, last_name, role, _id, phone, email })
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box height="100vh" bg="#F8FAFB">
        <Flex
          justify="space-between"
          align="center"
          p="2"
          boxShadow="sm"
          borderRadius="sm"
          pos="fixed"
          style={{ width: 'calc(100vw - 96px)' }}
          zIndex={1000}
        >
          <HStack spacing="4" p={2}>
            <Box position="relative" display="inline-block">
              <Button
                paddingX={6}
                leftIcon={<FiFilter />}
                rightIcon={<FiChevronsDown />}
                colorScheme="teal"
                variant="outline"
                borderRadius="md"
                size="sm"
                onClick={() => {
                  toggleSidebar();
                  setFilters(lsFilters);
                }}
              >
                Filter
              </Button>
              {filteredData?.length > 0 && (
                <Badge
                  position="absolute"
                  top="-3"
                  right="0"
                  colorScheme="teal"
                  borderRadius="full"
                  px={2}
                  fontSize="0.8em"
                >
                  {filteredData?.length}
                </Badge>
              )}
            </Box>
            <ManageColumns
              columns={columns}
              handleColumnChange={handleColumnChange}
              columnVisibility={columnVisibility}
              setManageColumnSelectAllCheckbox={
                setManageColumnSelectAllCheckbox
              }
              manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
            />
            <form onSubmit={handleInputFormSubmit}>
              <InputGroup size="sm">
                <SearchBar
                  background="#F9F9FB"
                  color="#637381"
                  setSearchText={(e) => setSearchText(e.target.value)}
                  searchText={searchText}
                  borderRadius="5px"
                  borderWidth="0.5px"
                  borderColor="#637381"
                />
              </InputGroup>
            </form>
          </HStack>
          <Text
            borderRadius="5px"
            borderWidth="0.5px"
            borderColor="#637381"
            background="#F9F9FB"
            color="#637381"
            p={1}
          >
            Total Users: {totalCount}
          </Text>
          <Button
            borderRadius="5px"
            borderColor="#637381"
            background={'linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)'}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal"
            onClick={() => setOpenLoginForm(true)}
          >
            Create User
          </Button>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => setCurrentPage(value)}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageSizeToLocalStorage={(value) =>
              setFilterToLocalStorage(
                ENTITY_FILTER_TYPES.USERS_PAGE_SIZE,
                value
              )
            }
          />
        </Flex>
        <SidebarFilter
          filters={allFiltersProps}
          handleFilterChange={handleFilterChange}
          handleApplyFilter={handleApplyFilter}
          isOpen={isOpenFilterSidebar}
          onClose={toggleSidebar}
          appliedFilters={filters}
          lsFilters={lsFilters}
          setFilters={setFilters}
          handleRemoveAllFilter={handleRemoveAllFilter}
          isCalenderFilter={true}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        {userData?.length !== 0 ? (
          <>
            <CustomTable
              columns={visibleColumns}
              data={data}
              onPhoneNumberClick={onPhoneNumberClick}
              jsonData={jsonData}
              onOpen={onOpen}
              onClose={onClose}
              isOpen={isOpen}
              onUpdateUser={handleUpdateUser}
              handleRowSelect={handleRowSelect}
              selectedRowsData={selectedRowsData}
              setSelectedRowsData={setSelectedRowsData}
              onSelectedAction={setSelectedAction}
              menuComponent={
                <Menu
                  data={Object.values(USER_STATUS)}
                  onUpdate={() => {}}
                  onUpdatePropertyStatus={handleUserAction}
                  selectedAction={selectedAction}
                />
              }
            />
          </>
        ) : !loading ? (
          <NoDataFound />
        ) : null}
        <OverlayLoaderComponent isOpen={loading} />
      </Box>
      <SignupFormModal
        isOpen={openLoginForm}
        onClose={() => setOpenLoginForm(false)}
        setOpenLoginForm={setOpenLoginForm}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setUserData={setUserData}
      />
      <UserRequirementModal
        isOpen={openRequirementForm}
        onClose={() => setOpenRequirementForm(false)}
        userId={selectedUser?._id}
        selectedData={{...selectedAction, full_name: selectedAction.name}}
      />
    </>
  );
}
